<ng-container *ngIf="isMobile; else desktop">
  <div *ngIf="mobileMenuOpen">
    <div class="black-screen"></div>
    <app-mobile-menu
      (clickOutside)="closeMenu()"
      (usedLink)="closeMenu()"
      [areNewMessages]="areNewMessages">
    </app-mobile-menu>
  </div>
  <div class="wrapper"
       *ngIf="headerInfo.isBackground">
    <div class="header-background "
         [ngClass]="{ onScroll: shrinkHeader }">
      <hr style="height: 0.8vh; visibility: hidden;"/>
      <div class="container space-evenly">
        <button *ngIf="headerInfo.isLeftIcon, else noLeftIcon"
                class="col-2 clear-button "
                (click)="clickOnLeftIcon()"
                routerLink="{{ headerInfo.leftIconRouterLink }}"
                [attr.aria-label]="headerInfo.leftIconName === 'Back' ?
                ('accessibility.back' | translate) : ('accessibility.goMainPage' | translate)">
          <img loading="lazy" class="{{ headerInfo.leftIconName }}"
               id="left-button"
               src="assets/header-icons/{{ headerInfo.leftIconName }}.svg"
               [alt]="headerInfo.leftIconName === 'Back' ?
               ('accessibility.back' | translate) : ('accessibility.goMainPage' | translate)"/>
        </button>

        <!--h1 tag sr-only added for mobile application accessibility score bypass reasons -->
        <h1 class="sr-only">{{ headerInfo.title | translate }}</h1>

        <button class="col-8 clear-button"
                style="color: white"
                id="header-title"
                (click)="clickOnTitle()">
          {{ headerInfo.title | translate | uppercase }}
        </button>

        <!-- menu button -->
        <button class="col-2 clear-button me-3"
             (click)="openMenu()"
                [attr.aria-label]="areNewMessages ? ('accessibility.openMenuNewMessage' | translate) : ('accessibility.openMenu' | translate)">
          <ng-container *ngIf="areNewMessages;then menuWithDot; else noNewMessages">

          </ng-container>
          <ng-template #menuWithDot>
            <img loading="lazy" class="menu"
                 id="right-button-dot"
                 src="assets/header-icons/Menu-dot.svg"
                 [alt]="'accessibility.openMenuNewMessage' | translate"/>
          </ng-template>
          <ng-template #noNewMessages>
            <img loading="lazy" class="menu"
                 id="right-button"
                 src="assets/header-icons/Menu.svg"
                 [alt]="'accessibility.openMenu' | translate"/>
          </ng-template>
        </button>

      </div>

      <div class="container space-evenly extra-info"
           id="extra-info"
           [ngClass]="{ hide: shrinkHeader }">
        <span id="patient-name"
              *ngIf="headerInfo.isPatientNameDisplayed && ((isLoggedIn$ | async) || isNewUser)">{{ patientName }}</span>
        <span id="info-text"
              *ngIf="headerInfo.isInfoText">{{headerInfo.infoText | translate}}</span>
      </div>
    </div>
    <div *ngIf="showProgressBar && isLoggedIn$ | async">
      <div class="motivation-progress-bar"
           [ngClass]="{ 'progress-mot': shrinkHeader }">
        <ngb-progressbar class="bar"
                         type="info"
                         [value]="overallProgressBarValue">
        </ngb-progressbar>
      </div>
    </div>
    <div class="header-padding"></div>
  </div>
</ng-container>
<ng-template #desktop>
  <div class="desktop-wrapper"
       *ngIf="headerInfo.isBackground">
    <div class="header-background py-3"
         [ngClass]="{ onScroll: shrinkHeader }">
      <div class="d-flex flex-column">
        <div class="desktop-row-1 d-flex px-4">

          <div class="col-3 d-flex align-items-center desktop-buttons">
            <button  *ngIf="headerInfo.isLeftIcon && headerInfo.isLeftIconDesktop, else noLeftIcon"
              class="h-100 clear-button desktop-home-button"
                    (click)="clickOnLeftIcon()"
                    routerLink="/"
                    id="home"
                    [attr.aria-label]="'accessibility.goMainPage' | translate">
              <img loading="lazy" src="assets/header-icons/Home-outline.svg" [alt]="'accessibility.goMainPage' | translate"/>
            </button>
            <ng-container *ngIf="isLoggedIn$ | async">
              <button class="h-100 clear-button desktop-settings-button"
                      (click)="clickOnLeftIcon()"
                      routerLink="/settings"
                      id="account"
                      [attr.aria-label]="'accessibility.goSettingsPage' | translate ">
                <img loading="lazy" src="assets/header-icons/Account.svg" [alt]="'accessibility.goSettingsPage' | translate">
              </button>

              <ng-container *ngIf="areNewMessages; then dotEnvelope; else noNewMessages"></ng-container>
              <ng-template #dotEnvelope>
                <button class="h-100 clear-button desktop-messages-button"
                        (click)="clickOnMessagesIcon()"
                        [attr.aria-label]="'accessibility.goMessagePageNewMessage' | translate">
                  <img loading="lazy" src="assets/header-icons/Messages-dot.svg"
                       [alt]="'accessibility.goMessagePageNewMessage' | translate"/>
                </button>
              </ng-template>
              <ng-template #noNewMessages>
                <button class="h-100 clear-button desktop-no-messages-button"
                        (click)="clickOnMessagesIcon()"
                        [attr.aria-label]="'accessibility.goMessagePage' | translate ">
                  <img loading="lazy" src="assets/header-icons/Messages.svg"
                       [alt]="'accessibility.goMessagePage' | translate"/>
                </button>
              </ng-template>
            </ng-container>
          </div>

          <h1 class="col-6 desktop-title"
              id="header-title">
            {{ headerInfo.title  | translate | uppercase }}
          </h1>

          <div class="col-3 d-flex justify-content-end">

            <div class="lang-menu">
              <button [class.active]="currentLanguage === 'ee'"
                      class="clear-button"
                      (click)="useLanguage('ee')" lang="ee">EST
              </button>
              <button [class.active]="currentLanguage === 'en'"
                      class="clear-button"
                      (click)="useLanguage('en')" lang="en">ENG
              </button>
              <button [class.active]="currentLanguage === 'ru'"
                      class="clear-button"
                      (click)="useLanguage('ru')" lang="ru">RUS
              </button>
            </div>

            <div id="log-out">
              <button class="logOut-text clear-button"
                      *ngIf="(isLoggedIn$ | async); else menuIcon"
                      (click)="onLogout()">
                {{ "settings.logOut" | translate | uppercase }}
              </button>
              <ng-template #menuIcon>
                <img loading="lazy" class="Menu-desktop"
                     (keydown.enter)="accessibility.handleKeyDown('welcome/menu')"
                     routerLink="welcome/menu"
                     src="assets/header-icons/Menu.svg"
                     [alt]="'accessibility.generalInformationIcon' | translate"/>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="container space-evenly extra-info"
             id="extra-info"
             [ngClass]="{ hide: shrinkHeader }">
          <span id="patient-name"
                *ngIf="headerInfo.isPatientNameDisplayed && ((isLoggedIn$ | async) || isNewUser)">{{ patientName }}</span>
          <span id="info-text"
                *ngIf="headerInfo.isInfoText">{{
            headerInfo.infoText | translate
            }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="showProgressBar && isLoggedIn$ | async">
      <div class="motivation-progress-bar"
           [ngClass]="{ 'progress-mot': shrinkHeader }">
        <ngb-progressbar class="bar"
                         type="info"
                         [value]="overallProgressBarValue">
        </ngb-progressbar>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noLeftIcon>
  <div class="col-2"
       id="pseudo-item-1"></div>
</ng-template>
