import { Routes } from '@angular/router';
import { AuthGuard } from '../../shared/authguards/auth.guard';

export const SETTINGS_ROUTES: Routes = [
  {
    path: "settings",
    loadComponent: () => import('./settings.component').then(m => m.SettingsComponent),
    canActivate: [AuthGuard],
    data: { title: 'pageTitles.settings' },
    children: [
      {
        path: "",
        loadComponent: () => import('./settings-menu/settings-menu.component').then(m => m.SettingsMenuComponent),
        data: { title: 'pageTitles.settings' }
      },
      {
        path: "user-profile",
        loadComponent: () => import('./user-profile/user-profile.component').then(m => m.UserProfileComponent),
        data: { title: 'pageTitles.userProfile' }
      },
      {
        path: "contact-info",
        loadComponent: () => import('./user-profile/user-profile.component').then(m => m.UserProfileComponent),
        data: { title: 'pageTitles.contactInfo' }
      },
      {
        path: "my-diseases",
        loadComponent: () => import('./my-diseases/my-diseases.component').then(m => m.MyDiseasesComponent),
        data: { title: 'pageTitles.myDiseases' }
      },
      {
        path: "my-doctor",
        loadComponent: () => import('./my-doctor/my-doctor.component').then(m => m.MyDoctorComponent),
        data: { title: 'pageTitles.myDoctor' }
      },
      {
        path: "my-apps",
        loadComponent: () => import('./my-apps/my-apps.component').then(m => m.MyAppsComponent),
        data: { title: 'pageTitles.myApps' }
      },
      {
        path: "contact",
        loadComponent: () => import('./contact/contact.component').then(m => m.ContactComponent),
        data: { title: 'pageTitles.technicalFeedback' }
      },
      {
        path: "consents",
        loadComponent: () => import('./my-consents/my-consents.component').then(m => m.MyConsentsComponent),
        data: { title: 'pageTitles.consents' }
      },
      {
        path: "my-paid-services",
        loadComponent: () => import('./my-paid-services/my-paid-services.component').then(m => m.MyPaidServicesComponent),
        data: { title: 'pageTitles.myPaidServices' }
      }
    ],
  },
];

