import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Subject} from "rxjs";
import {HeaderInfoService} from "../features/global-header/header-info.service";

@Injectable({
  providedIn: 'root'
})
export class NavigateToMessagesService {
  private urlsWithOwnMessages = ['prescriptions', 'diseases', 'terminate-leave', 'appointment', 'certificates', 'question'];

  constructor(private router: Router, private headerInfoService: HeaderInfoService) { }

  navigateToMessages() {
    if (this.router.url.endsWith('message')) {
      const url = this.router.url.slice(0, this.router.url.indexOf('/message'));
      this.router.navigate([url]);
      return;
    }
    const parts = this.router.url.split('/');
    if (parts.length > 1 && this.urlsWithOwnMessages.includes(parts[1])) {
      this.router.navigateByUrl(`/${parts[1]}/message`);
      return;
    }

    if (this.router.url === '/') {
      this.headerInfoService.toggleNotifications();
    } else {
      this.router.navigate(['/message']);
    }


  }

}
