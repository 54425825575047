import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  appName = "e-Perearstikeskus"

  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.titleService.setTitle('');
  }

  initialize() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === PRIMARY_OUTLET),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        if (event.title) {
          this.updateTitle(event.title);
        } else {
          this.resetTitle();
        }
      });
    this.translate.onLangChange.subscribe(() => {
      setTimeout(() => {
        let key = this.getCurrentRouteTitle();
        if (key !== '' && key !== undefined) {
          this.updateTitle(this.getCurrentRouteTitle());
        }
      });
    });
  }

  private updateTitle(titleKey: string) {
    this.translate.get(titleKey).subscribe(titleTranslation => {
      const fullTitle = `${titleTranslation} | ${this.appName}`;
      this.titleService.setTitle(`${fullTitle}`);
      this.updateAriaLiveRegion(fullTitle);
    });
  }

  private resetTitle() {
    const appTitle = this.titleService.getTitle();
    this.titleService.setTitle(appTitle);
    this.updateAriaLiveRegion(appTitle);
  }

  private updateAriaLiveRegion(title: string) {
    const ariaLiveRegion = document.getElementById('aria-live-region');
    if (ariaLiveRegion) {
      ariaLiveRegion.textContent = title;
    }
  }

  private getCurrentRouteTitle(): string {
    let route = this.activatedRoute;
    while (route.firstChild) route = route.firstChild;

    if (route.outlet === PRIMARY_OUTLET && route.snapshot.data.title) {
      return route.snapshot.data.title;
    }
    return '';
  }
}
