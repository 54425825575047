import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/authguards/auth.guard';
import {DIARY_ROUTES} from "./features/diary/diary-routing.module";
import {PRESCRIPTION_ROUTES} from "./features/prescriptions/prescription-routing.module";
import {APPOINTMENT_ROUTES} from "./features/appointment/appointment-routing.module";
import {QUESTION_ROUTES} from "./features/question/question-routing.module";
import {CERTIFICATES_ROUTES} from "./features/certificates/certificates-routing.module";
import {DISEASE_ROUTES} from "./features/diseases/disease-routing.module";
import {HEALTH_PLAN_ROUTES} from "./features/health-plan/health-plan-routing.module";
import {SETTINGS_ROUTES} from "./features/settings/settings-routing.module";
import {TERMINATE_ROUTES} from "./features/terminate-leave/terminate-routing.module";
import {LOGIN_ROUTES} from "./features/login/login-routing.module";
import {BEFORE_LOGIN_ROUTES} from "./features/before-login/before-login-routing.module";
import {VIDEO_ROUTES} from "./features/video/video-routing.module";
import {MAIN_ROUTES} from "./features/main/main-routing.module";
import {CROSS_COMMUNICATION_ROUTES} from "./features/outside-institution-message/cross-communication-routing.module";
import {GLOBAL_CONTENT_ROUTES} from "./features/global-content/global-content-routing.module";

const appRoutes: Routes = [
  ...MAIN_ROUTES,
  ...VIDEO_ROUTES,
  ...BEFORE_LOGIN_ROUTES,
  ...LOGIN_ROUTES,
  ...DIARY_ROUTES,
  ...PRESCRIPTION_ROUTES,
  ...APPOINTMENT_ROUTES,
  ...QUESTION_ROUTES,
  ...CERTIFICATES_ROUTES,
  ...DISEASE_ROUTES,
  ...HEALTH_PLAN_ROUTES,
  ...SETTINGS_ROUTES,
  ...TERMINATE_ROUTES,
  ...CROSS_COMMUNICATION_ROUTES,
  ...GLOBAL_CONTENT_ROUTES,
  {path: '**', redirectTo: '/'} // Always keep wildcard route last
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, {
  enableTracing: false,
  scrollPositionRestoration: 'top',
});
