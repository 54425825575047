import {Routes} from '@angular/router';
import {LoginGuard} from "../../shared/authguards/login.guard";
import {RoleGuard} from "../../shared/authguards/role.guard";
import {LeaveFormGuard} from "../../shared/authguards/leave-form.guard";
import {ExternalPatientRoleGuard} from "../../shared/authguards/external-patient-role.guard";

export const LOGIN_ROUTES: Routes = [
  {
    path: "login",
    loadComponent: () => import('./login.component').then(m => m.LoginComponent),
    canActivate: [LoginGuard, ExternalPatientRoleGuard],
    data: {title: 'pageTitles.login'},
    children: [
      {
        path: "",
        loadComponent: () => import('./landing/landing.component').then(m => m.LandingComponent),
        data: {title: 'pageTitles.login'}
      },
      {
        path: "mobile-id",
        loadComponent: () => import('./mobile-id/mobile-id.component').then(m => m.MobileIdComponent),
        data: {title: 'pageTitles.loginMobile'}
      },
      {
        path: "smart-id",
        loadComponent: () => import('./smart-id/smart-id.component').then(m => m.SmartIdComponent),
        data: {title: 'pageTitles.loginSmart'}
      },
      {
        path: "google-login",
        loadComponent: () => import('./google-login/google-login.component').then(m => m.GoogleLoginComponent),
        data: {title: 'pageTitles.loginGoogle'}
      },
      {
        path: "new-user",
        loadComponent: () => import('./new-user/new-user.component').then(m => m.NewUserComponent),
        data: {title: 'pageTitles.newUser'},
        canActivate: [RoleGuard],
        canDeactivate: [LeaveFormGuard],
      },
      {
        path: "new-user/terms",
        loadComponent: () => import('../global-content/terms/terms.component').then(m => m.TermsComponent),
        data: {title: 'pageTitles.userTerms'},
        canActivate: [RoleGuard],
      },
    ],
  },
];
