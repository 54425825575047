import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PwaUpdateService implements OnDestroy {
  private updateSubscription$ = new Subject<void>();

  constructor(private updates: SwUpdate) {
    if (updates.isEnabled) {
      this.checkForUpdatesOnInit();
      this.listenForVersionUpdates();
    } else {
      console.log('No service worker allowed');
    }
  }

  private checkForUpdatesOnInit(): void {
    // Check for updates every 5 minutes
    timer(0, 1000 * 60 * 5)
      .pipe(takeUntil(this.updateSubscription$))
      .subscribe(() => this.updates.checkForUpdate());
  }

  private listenForVersionUpdates(): void {
    this.updates.versionUpdates
      .pipe(takeUntil(this.updateSubscription$))
      .subscribe(event => {
        if (event.type === 'VERSION_READY') {
          console.log(`Current version is ${event.currentVersion.hash}`);
          console.log(`Available version is ${event.latestVersion.hash}`);
          this.promptUser();
        }
      });
  }

  private promptUser(): void {
    if (confirm('New version available. Load New Version?')) {
      this.updates.activateUpdate().then(() => document.location.reload());
    }
  }

  ngOnDestroy(): void {
    this.updateSubscription$.next();
    this.updateSubscription$.complete();
  }
}
