import {Routes} from '@angular/router';
import { AuthGuard } from '../../shared/authguards/auth.guard';
import { LeaveMotivationQuestionnaireGuard } from '../../shared/authguards/leave-motivation-questionnaire.guard';

export const HEALTH_PLAN_ROUTES: Routes = [
  {
    path: "health-plan",
    loadComponent: () => import('./health-plan.component').then(m => m.HealthPlanComponent),
    canActivate: [AuthGuard],
    data: { title: 'pageTitles.healthPlan'},
    children: [
      {
        path: "",
        loadComponent: () => import('./health-plan-menu/health-plan-menu.component').then(m => m.HealthPlanMenuComponent),
        data: { title: 'pageTitles.healthPlan'},
      },
      {
        path: "info",
        loadComponent: () => import('./health-plan-info/health-plan-info.component').then(m => m.HealthPlanInfoComponent),
        data: { title: 'pageTitles.healthPlanInfo'},
      },
      {
        path: "progress",
        loadComponent: () => import('./progress/progress.component').then(m => m.ProgressComponent),
        data: { title: 'pageTitles.healthPlanProgress'},
      },
      {
        path: "new",
        loadComponent: () => import('./new-data/goal-new-data.component').then(m => m.GoalNewDataComponent),
        data: { title: 'pageTitles.healthPlanNew'},
      },
      {
        path: "goal-info",
        loadComponent: () => import('./info/goal-info.component').then(m => m.GoalInfoComponent),
        data: { title: 'pageTitles.goalInfo'},
      },
      {
        path: "calendar",
        loadComponent: () => import('./calendar/goal-calendar.component').then(m => m.GoalCalendarComponent),
        data: { title: 'pageTitles.calendar'},
      },
      {
        path: "activities",
        loadComponent: () => import('./activities/activities.component').then(m => m.ActivitiesComponent),
        data: { title: 'pageTitles.activities'},
      },
      {
        path: "medication",
        loadComponent: () => import('./medication/medication.component').then(m => m.MedicationComponent),
        data: { title: 'pageTitles.medication'},
      },
      {
        path: "questionnaire",
        loadComponent: () => import('./motivation-questionnaire/motivation-questionnaire.component').then(m => m.MotivationQuestionnaireComponent),
        canDeactivate: [LeaveMotivationQuestionnaireGuard],
        data: { title: 'pageTitles.questionnaire'},
      },
    ],
  },
];
