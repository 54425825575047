import {Routes} from '@angular/router';
import {AuthGuard} from 'src/app/shared/authguards/auth.guard';
import {LeaveMotivationQuestionnaireGuard} from "../../shared/authguards/leave-motivation-questionnaire.guard";
import {ExternalPatientRoleGuard} from "../../shared/authguards/external-patient-role.guard";

export const DIARY_ROUTES: Routes = [
  {
    path: "diary",
    loadComponent: () => import('./diary/diary.component').then(m => m.DiaryComponent),
    canActivate: [AuthGuard, ExternalPatientRoleGuard],
    data: {title: 'pageTitles.diary'},
    children: [
      {
        path: "",
        loadComponent: () => import('./diary-menu/diary-menu.component').then(m => m.DiaryMenuComponent),
        data: {animation: "DiaryPage", title: 'pageTitles.diary'},
      },
      {
        path: "info",
        loadComponent: () => import('./diary-info/diary-info.component').then(m => m.DiaryInfoComponent),
        data: {animation: "DiaryPage", title: 'pageTitles.diaryInfo'},
      },
      {
        path: "asthma-test",
        loadComponent: () => import('./asthma-test-list/asthma-test-list.component').then(m => m.AsthmaTestListComponent),
        data: {animation: "AsthmaPage", title: 'pageTitles.asthmaTest'},
      },
      {
        path: "asthma-test/new",
        loadComponent: () => import('./asthma-test/asthma-test.component').then(m => m.AsthmaTestComponent),
        data: {animation: "TestPage", title: 'pageTitles.asthmaTestNew'},
      },
      {
        path: "asthma-test/info",
        loadComponent: () => import('./asthma-info/asthma-info.component').then(m => m.AsthmaInfoComponent),
        data: {animation: "TestPage", title: 'pageTitles.asthmaTestInfo'},
      },
      {
        path: "blood-pressure",
        loadComponent: () => import('./blood-pressure/blood-pressure.component').then(m => m.BloodPressureComponent),
        data: {animation: "PressurePage", title: 'pageTitles.bloodPressure'},
      },
      {
        path: "blood-pressure/new",
        loadComponent: () => import('./blood-pressure/new-blood-pressure/new-blood-pressure.component').then(m => m.NewBloodPressureComponent),
        data: {animation: "NewPressure", title: 'pageTitles.bloodPressureNew'},
      },
      {
        path: "blood-pressure/info",
        loadComponent: () => import('./blood-pressure/blood-pressure-info/blood-pressure-info.component').then(m => m.BloodPressureInfoComponent),
        data: {animation: "PressureInfo", title: 'pageTitles.bloodPressureInfo'},
      },
      {
        path: "blood-pressure/new/info",
        loadComponent: () => import('./blood-pressure/new-blood-pressure/new-blood-pressure-info/new-blood-pressure-info.component').then(m => m.NewBloodPressureInfoComponent),
        data: {animation: "NewPressureInfo", title: 'pageTitles.bloodPressureNewInfo'},
      },
      {
        path: "devices",
        loadComponent: () => import('./devices/add-devices/add-devices.component').then(m => m.AddDevicesComponent),
        data: {animation: "Devices", title: 'pageTitles.devices'},
      },
      {
        path: "devices/info",
        loadComponent: () => import('./devices/add-devices/add-devices-info/add-devices-info.component').then(m => m.AddDevicesInfoComponent),
        data: {animation: "DevicesInfo", title: 'pageTitles.devicesInfo'},
      },
      {
        path: "blood-sugar",
        loadComponent: () => import('./blood-sugar/blood-sugar.component').then(m => m.BloodSugarComponent),
        data: {animation: "SugarPage", title: 'pageTitles.bloodSugar'},
      },
      {
        path: "blood-sugar/info",
        loadComponent: () => import('./blood-sugar/blood-sugar-info/blood-sugar-info.component').then(m => m.BloodSugarInfoComponent),
        data: {animation: "SugarInfo", title: 'pageTitles.bloodSugarInfo'},
      },
      {
        path: "blood-sugar/new",
        loadComponent: () => import('./blood-sugar/new-blood-sugar/new-blood-sugar.component').then(m => m.NewBloodSugarComponent),
        data: {animation: "NewSugar", title: 'pageTitles.bloodSugarNew'},
      },
      {
        path: "blood-sugar/new/info",
        loadComponent: () => import('./blood-sugar/new-blood-sugar/new-blood-sugar-info/new-blood-sugar-info.component').then(m => m.NewBloodSugarInfoComponent),
        data: {animation: "NewSugarInfo", title: 'pageTitles.bloodSugarNewInfo'},
      },
      {
        path: "asthma-pef",
        loadComponent: () => import('./asthma-pef/asthma-pef.component').then(m => m.AsthmaPefComponent),
        data: {animation: "PefPage", title: 'pageTitles.asthmaPef'},
      },
      {
        path: "asthma-pef/info",
        loadComponent: () => import('./asthma-pef/asthma-pef-info/asthma-pef-info/asthma-pef-info.component').then(m => m.AsthmaPefInfoComponent),
        data: {animation: "PefInfo", title: 'pageTitles.asthmaPefInfo'},
      },
      {
        path: "asthma-pef/new",
        loadComponent: () => import('./asthma-pef/new-asthma-pef/new-asthma-pef.component').then(m => m.NewAsthmaPefComponent),
        data: {animation: "NewPef", title: 'pageTitles.asthmaPefNew'},
      },
      {
        path: "asthma-pef/new/info",
        loadComponent: () => import('./asthma-pef/new-asthma-pef/new-asthma-pef-info/new-asthma-pef-info.component').then(m => m.NewAsthmaPefInfoComponent),
        data: {animation: "NewPefInfo", title: 'pageTitles.asthmaPefNewInfo'},
      },
      {
        path: "questionnaire",
        loadComponent: () => import('../health-plan/motivation-questionnaire/motivation-questionnaire.component').then(m => m.MotivationQuestionnaireComponent),
        canDeactivate: [LeaveMotivationQuestionnaireGuard],
      },
      {
        path: "measurements",
        loadComponent: () => import('./measurements/measurements.component').then(m => m.MeasurementsComponent),
        data: {animation: "MeasurementsPage", title: 'pageTitles.measurements'},
      },
      {
        path: "measurements/info",
        loadComponent: () => import('./measurements/measurements-info/measurements-info.component').then(m => m.MeasurementsInfoComponent),
        data: {animation: "MeasurementsInfo", title: 'pageTitles.measurementsInfo'},
      },
      {
        path: "measurements/new",
        loadComponent: () => import('./measurements/new-measurements/new-measurements.component').then(m => m.NewMeasurementsComponent),
        data: {animation: "NewMeasurements", title: 'pageTitles.measurementNew'},
      },
      {
        path: "measurements/new/info",
        loadComponent: () => import('./measurements/new-measurements/new-measurements-info/new-measurements-info.component').then(m => m.NewMeasurementsInfoComponent),
        data: {animation: "NewMeasurementsInfo", title: 'pageTitles.measurementsNewInfo'},
      },
      {
        path: "periodic-feedback",
        loadComponent: () => import('./quarterly-feedback/quarterly-feedback.component').then(m => m.QuarterlyFeedbackComponent),
        data: {animation: "QuarterlyFeedBack", title: 'pageTitles.periodicFeedback'},
      },
      {
        path: "feedback",
        loadComponent: () => import('./general-feedback/general-feedback.component').then(m => m.GeneralFeedbackComponent),
        data: {animation: "FeedBack", title: 'pageTitles.feedback'},
      },
      {
        path: "mood-form",
        loadComponent: () => import('./mood-form/mood-form.component').then(m => m.MoodFormComponent),
        data: {animation: "QuarterlyFeedBack", title: 'pageTitles.moodForm'},
      }
    ],
  },
];
