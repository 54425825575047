import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Location} from "@angular/common";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private envVars: any = {};

  constructor(private http: HttpClient, private location: Location) {
  }

  async loadConfig(): Promise<void> {
    if (environment.production) {
      const basePath = this.location.prepareExternalUrl('/');
      const configUrl = `${basePath}api/config`;

      // Fire and forget: execute without waiting for the promise to resolve
      this.http.get(configUrl).subscribe({
        next: (response) => {
          this.envVars = response;

          // Updating environment variables
          environment.videoMeeting.domain = this.getConfig('EGP_JITSI_HOSTNAME') || environment.videoMeeting.domain;
          environment.logLevel = this.getConfig('EGP_PATIENT_LOG-LEVEL') ? this.getConfig('EGP_PATIENT_LOG-LEVEL').replace(" ", "").split(',') : environment.logLevel;
          environment.file.allowedExtensions = this.getConfig('EGP_FILE_ALLOWED-EXTENSIONS') ? this.getConfig('EGP_FILE_ALLOWED-EXTENSIONS').replace(" ", "").split(',') : environment.file.allowedExtensions;
          environment.file.maxFileSize = this.getConfig('EGP_FILE_MAX-SIZE') || environment.file.maxFileSize;
          environment.profiles = this.getConfig('EGP_SPRING_PROFILES') ? this.getConfig('EGP_SPRING_PROFILES').replace(" ", "").split(',') : environment.profiles;
          environment.googleLogin.clientId = this.getConfig('EGP_GOOGLE_CLIENT-ID') || environment.googleLogin.clientId;
          environment.googleLogin.enabled = this.getConfig('EGP_GOOGLE_ENABLE') === "true" || environment.googleLogin.enabled;
          environment.consentService.takeBackConsentUrl = this.getConfig('EGP_REMOVE_CONSENT_URL') != null ? this.getConfig('EGP_REMOVE_CONSENT_URL') : environment.consentService.takeBackConsentUrl;

          console.log('Environment variables loaded: ', this.envVars);
          console.log('Running environment is: ', environment);
        },
        error: (error) => {
          console.error('Failed to load configuration:', error);
          this.envVars = {}; // Reset to empty to use default environment values
        }
      });
    }
  }


  getConfig(key: string): any {
    return this.envVars[key] || null;
  }
}
