
<div>
  <a appSkipLink
     class="skip-link"
     tabindex="0"
     (focus)="onSkipLinkFocus()"
     (focusout)="onSkipLinkFocusOut()">
    {{ 'accessibility.skipToContent' | translate }}
  </a>
</div>




