<div class="alert-box">
    <p class="text-center" [ngClass]="{'font-1-8': !isMobile}">{{"healthPlan.dataUnsaved" | translate}}</p>
    <br>
    <div class="d-flex flex-column gap-05">
      <mat-dialog-actions [ngClass]="{'w-50': !isMobile}">
        <button
          mat-dialog-close
          type="button"
          (click)="onClose(false)"
          class="btn black-outline">{{ "shared.noStay" | translate }}
        </button>
      </mat-dialog-actions>
      <mat-dialog-actions [ngClass]="{'w-50': !isMobile}">
        <button
          [mat-dialog-close]="true"
          type="button"
          (click)="onClose(true)"
          class="btn black-outline"
        >{{ "shared.yesLeave" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
</div>

