import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserInfoService } from '../../services/user-info.service';

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  isAuthenticated: boolean;
  isNew: boolean;
  constructor(public authService: AuthService, public router: Router, public userService: UserInfoService) {
    this.authService.isLoggedIn().subscribe((value) => {
      this.isAuthenticated = value;
    });
    this.authService.isNew$.subscribe((value) => {
      this.isNew = value;
    });
  }
  canActivate(): boolean {
    if (!this.isNew) {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
