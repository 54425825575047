<div class="mobile-hamburger pt-5 d-flex; pe-4" [@fadeAndMove]="'in'">
  <div *ngFor="let item of menuItems;">
    <button *ngIf="!item.isHidden"
         [@fadeAndMove]="'in'"
         class="align-items-center item-icon-section clear-button"
         (click)="doClickAction(item.key)"
         (keydown.enter)="doClickAction(item.key)"
         [attr.aria-label]="item.description === 'shared.messages' ?
         (areNewMessages ? (('shared.messages' | translate) + ('accessibility.newMessage' | translate)) : ('shared.messages' | translate)) : item.description | translate">
      <div class="mobile-hamburger-item mobile-hamburger-text"
           [class.active]="item.isSelected">
        <span class="description-text">{{item.description | translate}}</span>
      </div>
      <div class="mobile-hamburger-item circle-icon"
           [class.active]="item.isSelected">
        <img loading="lazy" class="Messages-dot"
             [src]="item.iconLink"
             [alt]="item.key"
             *ngIf="!showLang(item.key); else langName"/>
        <ng-template #langName>
          <div class="lang-icon">
            {{(item.key === 'language' ? currentLanguage : item.key) | uppercase}}
          </div>
        </ng-template>
      </div>
    </button>
  </div>
</div>
