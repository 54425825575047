import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LeaveFormComponent } from '../leave-form/leave-form.component';
import { MotivationQuestionnaireComponent } from '../../features/health-plan/motivation-questionnaire/motivation-questionnaire.component';

@Injectable({
  providedIn: 'root'
})
export class LeaveMotivationQuestionnaireGuard implements CanActivate, CanDeactivate<MotivationQuestionnaireComponent> {
  constructor(private dialog: MatDialog) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(
    component: MotivationQuestionnaireComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!component.hasUnsavedData) {
      return true;
    } else {
      const dialogRef = this.dialog.open(LeaveFormComponent, {ariaLabel: 'info'});
      return  dialogRef.afterClosed();
    }
  }

}
