<ng-container *ngIf="isMobile; else desktop">
  <!--when in health plan or root-->
<ng-container *ngIf="isHealthPlan || isRoot && !isActivityReport && hasActivePlan">
  <button (click)="openSubMenu()" mat-fab
          [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
          class="main-button"
          [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isHealthPlan || isRoot}">
    <mat-icon class="icon-color" [ngClass]="{'rotate-close' : isSubMenuOpen, 'rotate-zero' : !isSubMenuOpen}">add
    </mat-icon>
  </button>
</ng-container>

<!--when in health plan activity new-->
<ng-container *ngIf="isActivityReport && hasActivePlan">
  <button (click)="saveAndGoBack()" mat-fab
          [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
          class="main-button" style="width: fit-content; border-radius: 20px;"
          [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isActivityReport}">
    <span style="padding: 5px 20px;
    display: inline-block;
    line-height: 24px;
    font-size: 20px;
    color: white;">{{'healthPlan.save' | translate}}</span>
  </button>
</ng-container>

<!--when in diary -->
<ng-container *ngIf="isDiary">
  <button (click)="goToLocation('/new')"
          mat-fab
          [attr.aria-label]="'diary.addNewMeasurement' | translate"
          class="main-button yellow-outline"
          [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isDiary}">
    <mat-icon class="icon-color">add</mat-icon>
  </button>
</ng-container>
</ng-container>

<ng-template #desktop>
  <!--when in health plan or root-->
  <ng-container *ngIf="isHealthPlan || isRoot && !isActivityReport && hasActivePlan">
    <button (click)="openSubMenu()"
            [tooltip]="$any(fabBtnTooltip)"
            contentType="template"
            mat-fab
            [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
            class="desktop-main-button"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isHealthPlan || isRoot}">
      <mat-icon class="icon-color"
                [ngClass]="{'rotate-close' : isSubMenuOpen, 'rotate-zero' : !isSubMenuOpen}">add
      </mat-icon>
    </button>
  </ng-container>

  <!--when in health plan activity new-->
  <ng-container *ngIf="isActivityReport && hasActivePlan">
    <button (click)="saveAndGoBack()"
            mat-fab
            [attr.aria-label]="'healthPlan.youCanAddMeasurements' | translate"
            class="desktop-main-button"
            style="width: fit-content; border-radius: 20px;"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isActivityReport}">
      <span style="padding: 5px 20px;
    display: inline-block;
    line-height: 24px;
    font-size: 20px;
    color: white;">{{'healthPlan.save' | translate}}</span>
    </button>
  </ng-container>

  <!--when in diary -->
  <ng-container *ngIf="isDiary">
    <button (click)="goToLocation('/new')"
            [tooltip]="$any(fabBtnTooltipDiary)"
            contentType="template"
            mat-fab
            [attr.aria-label]="'diary.addNewMeasurement' | translate"
            class="desktop-main-button yellow-outline"
            [ngClass]="{'fab-container-hide' : !isVisible, 'fab-container-show' : isVisible && isDiary}">
      <mat-icon class="icon-color">add</mat-icon>
    </button>
  </ng-container>
</ng-template>

<ng-template #fabBtnTooltip>
  <app-tooltip-info text="healthPlan.youCanAddMeasurements">
  </app-tooltip-info>
</ng-template>
<ng-template #fabBtnTooltipDiary>
  <app-tooltip-info text="diary.addNewMeasurement">
  </app-tooltip-info>
</ng-template>



