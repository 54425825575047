import { Routes } from '@angular/router';
import { AuthGuard } from '../../shared/authguards/auth.guard';
import { ExternalPatientRoleGuard } from '../../shared/authguards/external-patient-role.guard';

export const CROSS_COMMUNICATION_ROUTES: Routes = [
  {
    path: 'cross-communication',
    loadComponent: () => import('./cross-communication.component').then(m => m.CrossCommunicationComponent),
    canActivate: [AuthGuard, ExternalPatientRoleGuard],
    data: { title: 'pageTitles.crossCommunication' },
    children: [
      {
        path: '',
        loadComponent: () => import('./cross-communication-info/cross-communication-info.component').then(m => m.CrossCommunicationInfoComponent),
        data: { title: 'pageTitles.messageToAnotherCenter' },
      },
      {
        path: 'institution-list',
        loadComponent: () => import('./institution-list/institution-list.component').then(m => m.InstitutionListComponent),
        data: { title: 'pageTitles.practitioners' },
      },
      {
        path: 'institution-list/info',
        loadComponent: () => import('./cross-communication-header/cross-communication-header.component').then(m => m.CrossCommunicationHeaderComponent),
        data: { title: 'pageTitles.info' },
      },
      {
        path: 'review',
        loadComponent: () => import('./cross-communication-review/cross-communication-review.component').then(m => m.CrossCommunicationReviewComponent),
        data: { title: 'pageTitles.messageToAnotherCenterReview' },
      },
    ]
  },
];
