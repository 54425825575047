import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslationService } from '../../../services/translation-service';
import { NavigateToMessagesService } from '../../../services/navigate-to-messages.service';
import { AuthService } from '../../../services/auth.service';
import { NgDebounce } from '../../../decorators/debounce.decorator';
import { NgFor, NgIf, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.css'],
    animations: [
        trigger('fadeAndMove', [
            state('in', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            state('void', style({
                transform: 'translateY(-30px)',
                opacity: 0
            })),
            transition('in => void', [
                animate('200ms', style({ transform: 'translateY(-30px)', opacity: 0 }))
            ]),
            transition('void => in', [
                animate('200ms', style({ transform: 'translateY(0)', opacity: 1 }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgFor, NgIf, TranslateModule, UpperCasePipe]
})
export class MobileMenuComponent implements OnInit {
  currentLanguage: string;
  isMessage: boolean;
  isSettings: boolean;
  langMenuOpened = false;
  menuItems: MenuItem[];
  @Output() usedLink = new Subject();
  @Input() areNewMessages: boolean;
  private languages = ['ee', 'en', 'ru'];
  constructor(
    private translate: TranslateService,
    private translationService: TranslationService,
    private router: Router,
    private navigateToMessages: NavigateToMessagesService,
    private authService: AuthService) { }
  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.setMenuItemsSelection();
    this.setMenuItems();
    this.setMenuItemsSelection();
  }
  setMenuItems() {
    this.menuItems = [
      new MenuItem(
        'messages',
        'assets/scalableVectorGraphics/' + (this.areNewMessages ? 'messages-dot-dark' : 'message') + '.svg',
        'shared.messages',
        this.isMessage
      ),
      new MenuItem(
        'settings',
        'assets/scalableVectorGraphics/account-dark.svg',
        'shared.profile',
        this.isSettings
      ),
      new MenuItem(
        'language',
        'assets/scalableVectorGraphics/expand.svg',
        'shared.language',
        false
      ),
      new MenuItem(
        'ee',
        'ee',
        'Eesti',
        false,
        this.currentLanguage === 'ee' || !this.langMenuOpened
      ),
      new MenuItem(
        'en',
        'en',
        'English',
        false,
        this.currentLanguage === 'en' || !this.langMenuOpened
      ),
      new MenuItem(
        'ru',
        'ru',
        'Русский',
        false,
        this.currentLanguage === 'ru' || !this.langMenuOpened
      ),
      new MenuItem(
        'logOut',
        'assets/scalableVectorGraphics/logout.svg',
        'settings.logOut',
        false
      ),

    ];
  }

  updateMenuItems() {
    this.menuItems.forEach(item => {
      if(this.languages.includes(item.key)) {
        item.isHidden =
          (!this.languages.includes(item.key) || this.langMenuOpened)
          || item.key === this.currentLanguage;
      }
    })
  }

  doClickAction(key: string) {
    if (key === 'messages') this.goToMessage();
    if (key ==='settings') this.goToSettings();
    if (key === 'language') this.toggleLangMenu();
    if (key === 'logOut') this.onLogout();
    if (this.languages.includes(key)) this.useLanguage(key);
    this.updateMenuItems();
  }

  useLanguage(lang: string) {
    this.translate.use(lang);
    this.currentLanguage = lang;
    this.translationService.setLanguage(lang);
    this.usedLink.next(null);
  }

  setMenuItemsSelection() {
    const parts = this.router.url.split('/');
    if (parts.length > 1) {
      this.isSettings = parts[1] === 'settings';
      this.isMessage = parts[1] === 'message';
    }
  }

  goToMessage() {
    this.usedLink.next(null);
    this.navigateToMessages.navigateToMessages();
  }

  goToSettings() {
    this.usedLink.next(null);
    this.router.navigateByUrl('/settings');
  }

  @NgDebounce(300) toggleLangMenu(){
    this.langMenuOpened = !this.langMenuOpened;
  }

  showLang(key: string) {
    return this.languages.includes(key) || key === 'language' && !this.langMenuOpened;
  }

  onLogout() {
    this.usedLink.next(null);
    this.authService.clearSession();
  }
}

class MenuItem {
  constructor(
    public key: string,
    public iconLink: string,
    public description: string,
    public isSelected: boolean,
    public isHidden = false
  ) {
  }
}

/* Textsize MenuItem
  new MenuItem(
        'textSize',
        '/assets/scalableVectorGraphics/text-size.svg',
        'shared.changeTextSize',
        false
      ),
 */
