import {Routes} from '@angular/router';
import {AuthGuard} from "../../shared/authguards/auth.guard";

export const PRESCRIPTION_ROUTES: Routes = [
  {
    path: 'prescriptions',
    loadComponent: () => import('./prescription.component').then(m => m.PrescriptionComponent),
    data: {title: 'pageTitles.prescriptions'},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./prescription-list/prescription-list.component').then(m => m.PrescriptionListComponent),
        data: {title: 'pageTitles.prescriptions'}
      },
      {
        path: 'new',
        loadComponent: () => import('./new-prescription/new-prescription.component').then(m => m.NewPrescriptionComponent),
        data: {title: 'pageTitles.newPrescription'}
      },
      {
        path: 'info',
        loadComponent: () => import('./prescription-header/prescription-header.component').then(m => m.PrescriptionHeaderComponent),
        data: {title: 'pageTitles.prescriptionInfo'}
      },
      {
        path: 'review',
        loadComponent: () => import('./prescription-review/prescription-review.component').then(m => m.PrescriptionReviewComponent),
        data: {title: 'pageTitles.prescriptionReview'}
      },
      {
        path: 'message',
        loadComponent: () => import('../message/message.component').then(m => m.MessageComponent),
        data: {title: 'pageTitles.prescriptionMessages'}
      }
    ]
  }
];
